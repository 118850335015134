import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const BestPracticsPedagogSchool = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Школа передового педагогічного досвіду">

			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/19EF4y0fgPHsXWTYUtcnBCPUwOMpDTd3L/view?usp=sharing" target="_blanck">
					План роботи Школи передового педагогічного досвіду
					</a>
				</h4>

			</Pattern>
			
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default BestPracticsPedagogSchool;