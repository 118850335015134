import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const InsideRules = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="правила внутрішнього розпорядку">

			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1iAmfWa_8jauCqF54qdMEk_qFzeeQ3I2N/view?usp=drive_link" target="_blanck">Правила внутрішнього розпорядку</a>
			</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default InsideRules;