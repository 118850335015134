export const TITLE = [
	{
		allNews: 'ВСІ події'
	}
];

export const ARTICLES = [
	[
		
		{
			title: 'День української хустки',
			date: '07 грудня 2024 року',
			images: ['image/news/hystka1.jpg', 'image/news/hystka2.jpg',],
			text: '7 грудня-Всеукраїнський день української хустки!Саме українська хустка об’єднує жіночі покоління і передається як родинна цінність від матері до доньки,   символізує любов , свободу, вірність традиціям і патріотизм. Тож відроджуймо, поважаймо, популяризуємо  наші українські традиції - це генетичні скарби нашого народу.',
		},
		{
			title: 'СНІД - загроза людству',
			date: '03 грудня 2024 року',
			images: ['image/news/snid1.jpg', 'image/news/snid2.jpg','image/news/snid3.jpg',],
			text: '3 грудня 2024 року в КЗ ЛОР Львівському вищому професійному училищі комп’ютерних технологій та будівництва було проведено захід "СНІД - загроза людству", присвячений Всесвітньому дню боротьби зі СНІДом, який вперше проголосили 1-го грудня 1988 року Всесвітньою організацією охорони здоров’я. Кожен учасник заходу усвідомив усю небезпеку, яку несе СНІД, і робитиме все можливе, щоб уберегти себе і своїх близьких та рідних від цієї страшної інфекції.',
		},
		{
			title: 'Відкритий урок "Процес пайки друкованих систем"',
			date: '03 грудня 2024 року',
			images: ['image/news/payka1.jpg', 'image/news/payka2.jpg','image/news/payka3.jpg',],
			text: '03 грудня 2024 року в КЗ ЛОР "ЛВПУКТБ" у рамках міжатестаційного періоду майстер виробничого навчання Демчина М.І. провела  показовий (відкритий) урок виробничого навчання у групі №302 з професії 7241 Електромеханік з ремонту та обслуговування  лічильно-обчислювальних систем на тему: "Процес пайки друкованих систем". Урок отримав схвальні відгуки членів методичної комісії комп’ютерного напряму завдяки, зокрема, відзначено якісне навчально-методичне забезпечення уроку, високий рівень навченості здобувачів освіти, комфортні робочі місця в оновленій майстерні з ремонту та обслуговування ЛОМ, заздалегідь підготовлений відеоматеріал до уроку,  робоча емоційно позитивна атмосфера. Презентацію уроку можна переглянути за посиланням:',
			link: 'https://docs.google.com/presentation/d/1Szq3m2RmhJb7SzhzDKWxwtva778ONlPj/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true'
		},
		{
			title: 'Голодомор – 1932-1933 років: без права на забуття',
			date: '22 листопада 2024 року',
			images: ['image/news/golodomor24_2.jpg', 'image/news/golodomor24_1.jpg','image/news/golodomor24.jpg',],
			text: 'Щороку, в четверту суботу листопада, Україна вшановує пам’ять жертв Голодомору 1932–1933 років і масових штучних голодів 1921–1923рр.,1946–1947 рр. 22 листопада 2024р. у КЗ ЛОР "Львівське вище професійне училище комп’ютерних технологій та будівництва" проведено виховний захід на тему : «Голодомор – 1932-1933 років: без права на забуття».Здобувачі освіти долучились до акції « Запали свічку», як вияв скорботи за заморених голодом українців.',
		},
		{
			title: 'Революція Гідності 2013-2014',
			date: '21 листопада 2024 року',
			images: ['image/news/gidnist.jpg', 'image/news/gidnist1.jpg','image/news/gidnist2.jpg',],
			text: '21 листопада 2024 р. у КЗЛОР "Львівське вище професійне училище комп’ютерних технологій та будівництва" проведено виховний захід на тему : "Революція Гідності 2013-2014". Спроба перетворити Україну на проросійську диктатуру - саме те, проти чого повстало українське суспільство у 2013-2014 роках. Помстою за цей вибір українців стала агресія Росії, яка триває досі. Але, попри окупацію частини території, жахливі людські жертви та величезні матеріальні втрати, Україна не лише не зламалася, а й продовжила шлях до євроінтеграції, до якої так прагнули герої Майдану. Революція Гідності стала трансформаційним , героїчним і драматичним феноменом для України. Український народ довів свою відданість свободі, свою гідність, заплативши однак за такі цінності найвищу ціну - життям понад сотні учасників акції протесту, Героїв України, названих Небесною Сотнею...',
		},
		{
			title: 'Обласний фестиваль-конкурс "Пісенне сузір’я нескорених"',
			date: '20 листопада 2024 року',
			images: ['image/news/suziria2.jpg', 'image/news/suziria1.jpg','image/news/suziria3.jpg', ],
			text: '🎉 Вітаємо учнів групи 72-Т з почесним 3 місцем у обласному фестивальному конкурсі "Пісенне сузір’я нескорених"! 🌟 Ваш талант, завзятість і любов до пісні заслуговують найвищих похвал! 🏆Нехай це досягнення стане лише початком вашого творчого шляху, а попереду чекають нові перемоги та здобутки! 💫 Пишаємося вами! 👏',
		},
		{
			title: 'Знання та дружба - це найкраща зброя!!!',
			date: '07 листопада 2024 року',
			images: ['image/news/brain1.jpg', 'image/news/brain2.jpg', ],
			text: 'Знання та дружба - це найкраща зброя!!! 6 листопада  2024р. відбувся захопливий другий етап інтелектуальної гри "Брейн-ринг" серед учнів закладів професійної освіти Львова та області. Команда  NO NAME  КЗ ЛОР "Львівське вище професійне училище комп’ютерних технологій та будівництва"   виборола ІІІ місце   Вітаємо  наших переможців, та бажаємо надалі розвивати свої знання та вміння!!!🏆🏆🏆.',
		},
		{
			title: 'Змагання з волейболу. XII обласні спортивні ігри серед здобувачів освіти ПТНЗ Львівщини',
			date: '07 листопада 2024 року',
			images: ['image/news/volleyball2.jpg', 'image/news/volleyball1.jpg', 'image/news/volleyball3 (2).jpg', ],
			text: 'Вітаємо збірну команду дівчат Львівського вищого професійного училища комп’ютерних технологій та будівництва за зайняте II - ге місце в зональних змаганнях з волейболу ХII обласних спортивних іграх серед здобувачів освіти ПТНЗ Львівщини.',
		},
		{
			title: 'Табір підприємництва "Junior Achievement Ukraine"',
			date: '05 листопада 2024 року',
			images: ['image/news/5291987063085853715.jpg',],
			text: '5 листопада відбувся Табір підприємництва за програмою Junior Achievement Ukraine. Учні Львівського вищого професійного училища компʼютерних технологій та будівництва презентували свої бізнес ідеї. Такі соціальні ініціативи готують молодь до підприємницької діяльності, дають можливість отримати навички продажів а також поспілкувалися з успішними підприємцями.',
		},
		{
			title: '"Під синьо-жовтим прапором свободи з’єднаєм весь великий нарід свій..."',
			date: '05 листопада 2024 року',
			images: ['image/news/lystopadchyn.jpg', 'image/news/lystopadchyn1.jpg', 'image/news/lystopadchyn2.jpg', ],
			text: 'В КЗ ЛОР "Львівське вище професійне училище комп’ютерних технологій та будівництва відбувся виховний захід на тему: Листопадовий чин у серці українського народу, приурочений утворенню  ЗУНР. Проголошення ЗУНР 1-го листопада 1918 року стало подією загальнонаціональної ваги, яка згодом призвела до об’єднання 22 січня 1919 року всіх українських земель в єдину Соборну Українську Державу. Листопадовий чин став символом героїзму та жертовності, та залишається знаковою подією для історії України.',
		},  
		{
			title: '"Пригости мене розмовою українською мовою"',
			date: '28 жовтня 2024 року',
			images: ['image/news/pysemnist2.jpg', 'image/news/pysemnist1.jpg', 'image/news/pysemnist3.jpg', 'image/news/pysemnist4.jpg',],
			text: '"Пригости мене розмовою українською мовою", - під такою назвою   у КЗ ЛОР "Львівське вище професійне училище комп’ютерних технологій та будівництва" відбулась мовознавча кав’ярня, на якій  здобувачі освіти насолоджувались милозвучністю нашої мови.',
		},           											
		{
			title: 'Година спілкування на тему: "Емоції під контролем: три способи подолати тривогу"',
			date: '25 жовтня 2024 року',
			images: ['image/news/spilkuvanna.jpg',],
			text: 'Година спілкування на тему: "Емоції під контролем: три способи подолати тривогу" відбулася в нашому училищі для учасників освітнього процесу.Ці техніки допомагають опанувати емоції, пов’язані зі стресом, розвивати навички регуляції емоцій, виявляти та залучати ресурси, які у них є. Під час практики присутні мали змогу, дякуючи порадам експертам, зрозуміти свою тривогу, та методики, які допоможуть подолати її.Читайте повну версію тут:',
			link: 'https://drive.google.com/file/d/13hACZ2LyVLyTlPqPHVlQqTluhvIbrNZg/view?usp=drive_link'
														
		},
		{
			title: 'День створення УПА',
			date: '14 жовтня 2024 року',
			images: ['image/news/UPA2024.jpg', 'image/news/UPA2024_1.jpg', 'image/news/UPA22024_2.jpg'],
			text: '14 жовтня Україна відзначала День створення УПА. В КЗ ЛОР  Львівське вище професійне училище комп’ютерних технологій та будівництва  відбувся виховний захід, присвячений цьому величному дню. Воїни УПА , як і сьогодні наші воїни,  героїчно виборювали Українську державність , боролись за незалежну, вільну Україну. Здобувачі  освіти відтворили події цих буремних років та вшанували хвилиною мовчання всіх загиблих Героїв ,які віддали своє життя за волю України. "Немає найменших підстав сумніватися, що настане час, коли затріщить московсько-більшовицька імперія і розсиплеться на порох, а на її руїнах поряд з іншими вільними народами постане визволена з нестерпного рабства Україна, будуючи власне життя, у власній державі, ні від кого незалежній державі" Андрій Мельник.'
														
		},
		{
			title: 'З Днем Захисників та Захисниць України!',
			date: '01 жовтня 2024 року',
			images: ['image/news/zahusnyk.jpg',],
			text: '1 жовтня українці святкують одне з найвеличніших свят - Покрову Пресвятої Богородиці, яка була надзвичайно шанованою серед українського козацтва. Невипадково цей день обрано професійним святом Захисників та Захисниць України.Тому, сьогодні,  вся Україна на хвилину  зупинилась в знак пам’яті  за полеглими захисниками, які віддали своє життя, захищаючи нашу Державу... КЗ ЛОР "ЛВПУ КТБ" вітає всіх, хто причетний до цього величного свята . Низький уклін та шана всім, хто виборює незалежність України!!!'
														
		},
		{
			title: 'Трагедія Бабиного Яру – в серці українського народу',
			date: '30 вересня 2024 року',
			images: ['image/news/BabynYar3.jpg','image/news/BabynYar2.jpg','image/news/BabynYar1.jpg',],
			text: '30 вересня 2024 р. у  КЗ ЛОР "Львівське вище професійне училище комп’ютерних технологій та будівництва" проведено виховний захід  на тему: "Трагедія Бабиного Яру – в серці українського народу". Бабин Яр став широко відомим в Україні та поза її межами місцем масових розстрілів німецькими окупантами мирного населення. Тому, як ніколи, важливо осмислити все жахіття Голокосту. Мир, безпека та рівновага в світі є важливими складовими , які не дозволять цьому повторитися...'
														
		},
		{
			title: 'БРАМА-ОНЛАЙН ВАРТА УКРАЇНИ!',
			date: '24 вересня 2024 року',
			images: ['image/news/bramaonline.jpeg'],
			text: 'Щоденно, гортаючи стрічку у своїх соціальних мережах та месенджерах ми натикаємось на інформацію, яка на нашу думку є неправдивою або небезпечною. У свідомої людини виникає природнє бажання прибрати таку інформацію із медіа простору або заблокувати її поширення.Для блокування того чи іншого джерела необхідно надсилати скарги і чим більше буде скарг, тим більша вирогідність того, що джерело буде заблоковано. Саме із цією метою ми пропонуємо нашим користувачам стати частиною "BRAMA". Читайте повну версію тут:',
			link: 'https://icsa.team/214-2/'
														
		},
		{
			title: 'Відкритий урок "Зарубіжна література ХХ ст."',
			date: '13 червня 2024 року',
			images: ['image/news/zar1.jpeg', 'image/news/zar2.jpeg', 'image/news/zar3.jpeg', 'image/news/zar4.jpeg'],
			text: '13.06.2024 р. проведено відкритий урок в гр. 202 викладачем Шафранською Л. М. на тему: «Зарубіжна література ХХ ст. Антиутопія, проблема війни і миру, людина та пошуки сенсу існування». Завданням уроку було сформувати компетентності: предметні (розуміння значення художньої літератури для розвитку суспільства й особистості), ключові (уміння вчитися, прагнення літературної освіченості), інформаційні (уміння самостійно опрацьовувати джерела інформації та презентувати її), загальнокультурні (прагнення збагатити свій світогляд).'
														
		},
		{
			title: 'Тренінг "Інструменти усвідомлених подорожей"',
			date: '05 червня 2024 року',
			images: ['image/news/Travel2.jpeg', 'image/news/Travel1.jpeg', 'image/news/Travel3.jpeg', 'image/news/Travel4.jpeg'],
			text: '05 червня у ДПТНЗ "ЛВПУКТБ" відбувся тренінг на тему "Інструменти усвідомлених подорожей", на якому учні змогли дізнатись про правила безпечних мандрівок та працевлаштування у кожній точці світу. Дякуємо за надану інформаційну допомогу Pavlo Koropetski (психологічна служба ЛНУ ім. І. Франка).'
														
		},
		{
			title: 'Захід до Дня Європи',
			date: '20 травня 2024 року',
			images: ['image/news/Europe1.jpeg', 'image/news/Europe.jpeg', 'image/news/Europe2.jpeg'],
			text: 'У ДПТНЗ ЛВПУКТБ в рамках святкування Дня Європи відбувся позакласний захід "Україна - серце Європи", на якому учні змогли віртуально помандрувати країнами Європи та  зрозуміти вагому роль України  на політичній арені світу.'
														
		},
		{
			title: 'На Чорнобиль журавлі летіли, з вирію вертались на весні. Як сніжниця попелище біле розвівалось в рідній стороні...',
			date: '26 квітня 2024 року',
			images: ['image/news/Chornobil4.jpeg', 'image/news/Chornobil1.jpeg', 'image/news/Chornobil3.jpeg', 'image/news/Chornobil2.jpeg'],
			text: 'Сьогодні , 26.04.2024 р., Україна вшановує  пам’ять  жертв Чорнобильської катастрофи. Це страшний урок усьому людству і наша велика біль. Чорнобильська катастрофа стала попередженням усьому людству, наскільки крихким може бути наше майбутнє з неконтрольованою радіацією. Низький уклін усім героям, які ціною власного здоров’я ліквідовували наслідки Чорнобиля. 	У ДПТНЗ "Львівське вище професійне училище комп’ютерних технологій та будівництва" відбувся  тематичний захід на тему: "Дзвони Чорнобиля", на якому учні мали можливість переглянути мультимедійну презентацію  та відео про  Чорнобильську аварію, яка призвела до  жахливих  наслідків, за які заплачено дорогою ціною - життям!!!'
														
		},
		{
			title: 'Заходи по протидії тероризму',
			date: '12 березня 2024 року',
			images: ['image/news/antiter2.jpeg', 'image/news/antiter1.jpeg', 'image/news/antiter3.jpeg', 'image/news/antiter4.jpeg', 'image/news/antiter5.jpeg', 'image/news/antiter6.jpeg'],
			text: 'З 12 березня 2024 р. у ДПТНЗ «ЛВПУ КТБ» розпочалось проведення циклу заходів по протидії тероризму. Юрисконсульт Н. Косаревич та соціальний педагог Х. Друк провели правороз’яснювальну роботу серед здобувачів освіти та наголосили про відповідальність осіб у разі здійснення правопорушення, передбачені ст. 258, 259 та 22 ККУ.	Здобувачі освіти отримали пам’ятки щодо обачності використання своїх персональних даних в соцмережах, а також ознайомились з алгоритмом дій у разі настання надзвичайної ситуації. Попереджений – значить озброєний!'
														
		},
		{
			title: '"Борітеся – поборете, вам Бог помагає! За вас правда, за вас слава. І воля святая!»',
			date: '11 березня 2024 року',
			images: ['image/news/Shevchenko2.jpg', 'image/news/Shevchenko1.jpg', 'image/news/Shevchenko3.jpg'],
			text: 'Минуло 210 років з дня народження  Великого Кобзаря, якого знає цілий світ. Кожне його слово і досі актуальне та незабуте. Його голос крізь віки закликає кожного українця до боротьби за свою Батьківщину. 11.03.2024р.  у Львівському вищому професійному училищі комп’ютерних технологій та будівництва відбувся літературно -мистецький захід "...І мене в сім’ї великій ...", в якому учасники  залюбки розповідали поезії та разом із головним героєм твору "Гайдамаки" Яремою Галайдою мандрували сучасною Україною. Єднаймося, бо ми - українці. Пишаймося, бо ми - нащадки славного Тараса!!!'
														
		},
		{
			title: 'БРАМА-ОНЛАЙН ВАРТА УКРАЇНИ!',
			date: '29 лютого 2024 року',
			images: ['image/news/bramaQR.jpg'],
			text: 'Діяльність проєкту БРАМА направлена на захист медіа простору  від російської пропаганди, блокування каналів поширення дезінформації,  протиправного та забороненого контенту. Також, метою проєкту є покращення медіа-грамотності українців, зменшення впливу пропаганди на суспільство та популяризація безпечного поводження в мережі інтернет.Читайте повну версію тут:',
			link: 'https://udsh.info/144/196/86-86.html'
														
		},
		{
			title: 'Чудова українська мова, не загинула вона у течії віків. Мова солов’їна, мова колискова...',
			date: '21 лютого 2024 року',
			images: ['image/news/Ukr.mova1.jpeg', 'image/news/Ukr.mova2.jpeg', 'image/news/Ukr.mova3.jpeg'],
			text: '21 лютого, у Міжнародний день рідної мови, в ДПТНЗ Львівське вище професійне училище комп’ютерних технологій та будівництва відбувся захід на тему: "О мово рідна! Золота колиска", на якому здобувачі освіти пізнали всю велич і красу рідної мови.'
														
		},
		{
			title: 'День пам’яті Героїв Небесної Сотні',
			date: '20 лютого 2024 року',
			images: ['image/news/Nebesna3.jpeg', 'image/news/Nebesna2.jpeg', 'image/news/Nebesna1.jpeg'],
			text: '20 лютого Україна вдесяте вшановує пам’ять Героїв Небесної Сотні. У цей день,  під час Революції Гідності, відбулись масові розстріли протестувальників та загинуло найбільше активістів. В ДПТНЗ Львівське вище професійне училище комп’ютерних технологій та будівництва відбувся виховний захід,  приурочений цим трагічним подіям на тему: ,, Країна на коліна не встане  - Героям вічна слава!"  Пам’ятаємо...  Герої не вмирають, вмирають вороги!'
														
		},
		{
			title: 'День пам’яті Героїв Крут',
			date: '29 січня 2024 року',
			images: ['image/news/kruty2.jpeg', 'image/news/kruty1.jpeg'],
			text: '29 січня Україна відзначає День пам’яті Героїв Крут. Цього дня ми віддаємо шану стійкості та мужності молодих хлопців, які так само, як і сьогоднішні захисники України, стали на захист української держави. В ДПТНЗ Львівське вище професійне училище комп’ютерних технологій та будівництва відбувся виховний захід, приурочений пам’яті Героїв Крут, як символ патріотизму та героїчної боротьби  за Україну!!!'
														
		},
		{
			title: 'День Соборності України',
			date: '22 січня 2024 року',
			images: ['image/news/sobornist2.jpeg', 'image/news/sobornist1.jpeg'],
			text: 'Сьогодні, 22 січня, в ДПТНЗ "Львівське вище професійне училище комп’ютерних технологій та будівництва" відбувся виховний захід, приурочений Дню Соборності України на тему: "Єдина країна- єдиний народ". Слова "Я -Українець" мають особливе значення. Вони наповнені патріотизмом, рішучістю та незламністю. Українці, взявшись за руки, символізують єдність та цілісність нашої держави. Лише в єдності наша сила!!!'
														
		},
		{
			title: 'День створення  Збройних сил України',
			date: '06 грудня 2023 року',
			images: ['image/news/photo1702042507.jpeg', 'image/news/photo1702042519.jpeg', 'image/news/photo1702042554.jpeg'],
			text: 'Те що, відбувається, і те, що ми тримаємось, – це українські люди. Це український солдат і сержант, український офіцер, які щодня гинуть, калічаться, але тримають свої позиції." (Валерій Залужний). Сьогодні, 6 грудня, в ДПТНЗ Львівське вище професійне училище комп’ютерних технологій та будівництва відбувся виховний захід, приурочений Дню створення  Збройних сил України. ЗСУ- міцний щит України, мужні воїни української армії, найкращі сини і дочки українського народу, які твердо тримають ворожий удар та б’ють ворога, неухильно наближаючи ПЕРЕМОГУ!!!Слава ЗСУ! Слава Україні! Героям Слава!!!'
											
		},
		{
			title: 'Платіжна безпека: основні поради від Кіберполіції',
			date: '29 листопада 2023 року',
			images: ['image/news/PaymentSecure.jpg'],
			text: 'Схема шахрайства може бути абсолютно новою або добре прихованою. Тому, крім перевірки сайту на сайті Кіберполіції в розділі "Стоп фрауд" та сервісі Асоціації "ЄМА" CheckMyLink, проводьте також власну перевірку. Якщо ви випадково розкрили дані платіжної картки на підозрілому сайті, негайно телефонуйте до банку за номером, зазначеним на звороті картки. Якщо ви стали жертвою шахраїв, напишіть заяву до Кіберполіції за цим посиланням або повідомте про ваш випадок за номером телефону – 0 800 505 170. Захистіть свої акаунти двічі. Читайте повну версію тут:',
			link: 'https://prpravda.in.ua/107/4457-4457.html'
								
		},
		{
			title: 'Щодо протидії кібершахрайству',
			date: '29 листопада 2023 року',
			images: ['image/news/mriya.png'],
			text: 'Проект MRIYA – це ініціатива з протидії російським окупантам в медіа-просторі, блокуванню ресурсів шахраїв та ресурсів, за допомогою яких здійснюється збут наркотичних речовин. На сьогодні, це найбільша спільнота, яка успішно блокує та протидіє російській агресії в Інтернеті. Екосистема «МРІЯ» створена для об’єднання проектів, які розроблені у синергії Кіберполіції України та волонтерів.',
			link: 'https://prpravda.in.ua/107/4458-4458.html'
						
		},
		{
			title: 'Шануємо пам’ять жертв голодомору',
			date: '24 листопада 2023 року',
			images: ['image/news/golod2.jpeg', 'image/news/golod1.jpeg','image/news/golod.jpeg'],
			text: '24 листопада в ДПТНЗ ЛВПУКТБ проведено захід приурочений вшануванню 90-х роковин памʼяті жертв Голодомору 1932-1933 років в Україні - це вшанування памʼяті мільйонів українців, які стали жертвами штучного голоду, організованого сталінським режимом.	Голодомор є частиною української історії, і важливо, щоб про нього знали люди в Україні та в усьому світі.'
						
		},
		{
			title: '21 листопада 2023 року - Україна відзначає  10 річницю Революції Гідності та 19 річницю початку Помаранчевої революції',
			date: '21 листопада 2023 року',
			images: ['image/news/photo1701696227.jpeg','image/news/photo1701696254.jpeg','image/news/photo1701696242.jpeg',],
			text: 'Сьогодні Україна знову ціною життів виборює своє право на Гідність і Свободу - про це говорилося на виховному заході "Ми не зганьбили славу наших предків,нових Героїв дали небеса",який підготували здобувачі освіти закладу під керівництвом викладача Дикої  Л.Є.	На заході  відзначалося,що українці є свідомими того,що разом повинні боронити свою країну від ворога і щодня памʼятати, що Україна - це не тільки земля,а й наші рідні та друзі,які вірять і чекають вдома на  захисників. Слава Україні! Героям слава!'
						
		},
		{
			title: 'День автомобіліста',
			date: '03 листопада 2023 року',
			images: ['image/news/Eksk2.jpg', 'image/news/Eksk1.jpg','image/news/Eksk3.jpg', 'image/news/Eksk4.jpg' ],
			text: 'Укотре відбулася традиційна екскурсія здобувачів освіти з професії "Слюсар з ремонту колісних транспортних засобів" (гр.114, 69Т) ДПТНЗ "ЛВПУ КТБ" на підприємство НІКО Захід Преміум. Директор СТО Стахів Ярослав Петрович розповів про сучасні технології проведення ТО, спеціальне діагностичне обладнання та ремонт транспортних засобів. Неймовірні враження від екскурсії, розуміння важливості професії автомеханіка та бажання завітати на екскурсію знову.'
						
		},
		{
			title: 'Листопадовий зрив - під синьо-жовтим прапором свободи',
			date: '01 листопада 2023 року',
			images: ['image/news/LZ.jpeg', 'image/news/LZ2.jpeg'],
			text: '1 листопада 2023 року відбувся виховний захід присвячений 105 річниці створення ЗУНР "Листопадовий зрив - під синьо-жовтим прапором свободи", під час якого учні 114 групи (кл. керівник Бакун О.І.) розповіли про хід листопадового повстання у Львові, досвід проголошення Західноукраїнської Народної Республіки та героїчної боротьби УГА, що вкотре засвідчили прагнення та рішучість українського народу до волі та незалежності. Сила українського народу - у єдності! Непереможність - у честі і відвазі воїнів-героїв. Слава й шана усім полеглим Героям України!'
						
		},
		{
			title: 'День української писемності та мови',
			date: '27 жовтня 2023 року',
			images: ['image/news/UA4.jpeg', 'image/news/UA.jpeg', 'image/news/UA1.jpeg','image/news/UA2.jpeg','image/news/UA3.jpeg'],
			text: 'Традиції не змінюємо...Щороку тисячі українців у різних куточках світу та України сідають одночасно, щоб взяти участь в загальноукраїнському флешмобі. Наш заклад, ДПТНЗ ЛВПУКТБ, не виключення. Учні, викладачі та майстри виробничого навчання взяли участь у написанні Радіодиктанту національної єдності, який цьогоріч називався ,,Дороги України". Вкотре переконуємось, що в єдності наша сила!!!'
						
		},
		{
			title: 'Hагородження збірної команди училища з футболу за зайняте І місце у Кубку закладів професійно-технічної освіти міста Львова',
			date: '19 жовтня 2023 року',
			images: ['image/news/football1.jpeg', 'image/news/football2.jpeg', 'image/news/football3.jpeg','image/news/football4.jpeg'],
			text: '19 жовтня проведено нагородження збірної команди училища з футболу за зайняте І місце у Кубку закладів професійно-технічної освіти міста Львова. '
						
		},
		{
			title: 'Героїв Україна пам’ятає',
			date: '16 жовтня 2023 року',
			images: ['image/news/UPA3.jpeg', 'image/news/UPA1.jpeg', 'image/news/UPA2.jpeg'],
			text: '16 жовтня 2023р. у ДПТНЗ «ЛВПУ КТБ» відбувся виховний захід на тему - "Героїв Україна пам"ятає", присвячений 81 річниці створення УПА. Захід підготували учні 394 групи під керівництвом класного керівника Варлам Г.М. та майстра виробничого навчання Гудими Н.В. Метою проведення заходу було проінформувати учнів про створення і діяльність УПА та розвинути в них почуття патріотизму, національної гордості та любові до України. '
						
		},
		{
			title: 'Результати конкурсу на заміщення вакантної посади директора Державного професійно-технічного навчального закладу «Львівське вище професійне училище комп’ютерних технологій та будівництва»',
			date: '13 жовтня 2023 року',
			images: ['image/news/photo.jpg'],
			text: 'За результатами проведеного конкурсу на заміщення вакантної посади  директора Державного професійно-технічного навчального закладу «Львівське вище професійне училище комп’ютерних технологій та будівництва» переможцем конкурсу обрано Поліщук Марію Богданівну.',
						
		},
		{
			title: '*Як козак засміється – ворог з страху трясеться, як козак заспіває – той чимдуж утікає! Як козак затанцює – увесь світ його чує, зелен-дубе розвивайся, гей душа, розкриляйся!*',
			date: '04 жовтня 2023 року',
			images: ['image/news/kozak.jpeg'],
			text: '04 жовтня 2023 року у Львівському вищому професійному училищі комп’ютерних технологій та будівництва відбулося спортивно-мистецьке свято «Козацькі забави». Мета: розширити знання учнів про запорізьких козаків, їх традиції, побут, звичаї; виховувати шанобливе ставлення до запорожців, спонукати до наслідування їх кращих рис характеру: добродушності, безкорисливості, товариськості, бажання завжди прийти на допомогу.'
						
		},
		{
			title: 'Вітаємо Вас, любі педагоги',
			date: '29 вересня 2023 року',
			images: ['image/news/teach-1.png'],
			text: '29.09.2023р. у ДПТНЗ «ЛВПУ КТБ» було проведене свято, приурочене "Дню працівника освіти". Захід проводився у форматі конкурсу між командами учнів та викладачів. Конкурсні питання були різнопланові: професійні, гумористичні , логічні. Учасники виявили креативність, азарт і бажанням перемогти. Кращі працівники закладу освіти були відзначені грамотами Львівської обласної організації профспілки працівників освіти і науки України.'
			
		},
		
		{
			title: 'Оголошення про проведення конкурсу на заміщення вакантної посади директора Державного професійно-технічного навчального закладу «Львівське вище професійне училище комп’ютерних технологій та будівництва»',
			date: '31 серпня 2023 року',
			images: ['image/news/photo.jpg'],
			text: 'Департамент розвитку Львівської міської ради оголошує конкурс на заміщення вакантної посади директора Державного професійно-технічного навчального закладу «Львівське вище професійне училище комп’ютерних технологій та будівництва» (м. Львів, вул. Авраама Лінкольна, 23).',
			link: 'https://city-adm.lviv.ua/announcement/298149-oholoshennia-pro-provedennia-konkursu-na-zamishchennia-vakantnoi-posady-dyrektora-derzhavnoho-profesiino-tekhnichnoho-navchalnoho-zakladu-lvivske-vyshche-profesiine-uchylyshche-kompiuternykh-tekhnolohii-ta-budivnytstva'
			
		},
		{
			title: 'Національна поліція  України попереджає про шахрайства!',
			date: '23 червня 2023 року',
			images: ['image/news/kiberpolice.jpg'],
			text: 'Війна в Україні стала підґрунтям для поширення шахрайських схем, заснованих на вразливому емоційному стані та зловживанні вашою довірою. При цьому кмітливість шахраїв виходить за рамки вже добре знайомих українцям афер, тож з’являються нові способи ошукування. Національна поліція України розповідає про найпоширеніші схеми шахрайств та основні правила безпеки, які допоможуть викрити аферистів.',
			link: 'https://drive.google.com/file/d/1vUvZYJhYL0kIU01bBnesezsObpqXRdQq/view?usp=drive_link'
			
		},
		{
			title: 'Тренінг «Основи кібергігієни»',
			date: '11 травня 2023 року',
			images: ['image/news/seminar.jpg'],
			text: `11 травня 2023 року у рамках реалізації державної політики України у сфері протидії кіберзлочинності у ДПТНЗ  «ЛВПУКТБ» за підтримки департаменту освіти і науки Львівської ОДА відбувся тренінг «Основи кібергігієни». Тренер Мирон Пацай, заступник начальника відділу технічного нагляду та комп’ютерного забезпечення Департаменту освіти і науки Львівської ОДА, тренер Української школи урядування, надав практичні рекомендації викладачам та майстрам виробничого навчання з безпеки користування соціальними мережами та гаджетами, ознайомив з правовими засадами кібергігієни та можливостями захисту персональних даних. Під час професійної дискусії говорили про те, як не піддатись маніпуляціям з боку кіберзлочинців, як захистити свої дані та зберегти конфіденційність, як захистити свої гроші в інтернеті… Сьогодні, в умовах повномасштабного вторгнення росії, маємо бути свідомими та обізнаними!`
		},
		{
			title: 'У Львівському вищому професійному училищі компютерних технологій та будівництва відкрили цифровий навчальний центр від "Save the Children"',
			date: '05 квітня 2023 року',
			images: ['image/news/main1.jpg', 'image/news/main.jpg',  'image/news/centr2.jpg', 'image/news/centr3.jpg', 'image/news/Paska.jpg', 'image/news/children.jpg', 'image/news/children3.jpg'],
			text: `На відкритті були присутні заступник начальника ЛОДА Іван Собко, директор департаменту освіти та науки ЛОДА Олег Паска, начальник професійної освіти департаменту розвитку ЛМР Олена Грицак, директорка західного регіону «Save the Children» Малмі Вірасірі.
			
			«Save the Childrтзь іефкеen» – це міжнародна неурядова організація, яка вже понад 100 років допомагає дітям та молоді з різних куточків світу. Організація забезпечує доступ до якісної освіти і захищає дітей від наслідків війни та насильства.
			Завдяки цифровому навчальному центру, до якого сьогодні урочисто перерізали стрічку, діти віком від 6 до 17 років зможуть навчатись та розвиватись, отримають доступ до навчальних програм МОН України та зможуть користуватись усіма необхідними для навчання ґаджетами. Центр обладнаний комп'ютерами, ноутбуками, планшетами, екраном та проєктором. Забезпечено також стабільне підключення до інтернету. Завдяки затишній та дружній атмосфері діти можуть сконцентруватись на навчанні, поспілкуватися з однолітками та взяти участь у цікавих навчальних заходах.
			
			Освітній процес – це не просто про навчання, а й про атмосферу, в якій діти можуть розвиватись та соціалізуватись. Тому «Save the Children» має на меті розвиток онлайн-навчання, ми хочемо створювати такі центри, щоб діти мали доступ до якісної освіти, не перериваючи навчальний процес. Наш центр дуже сучасний, тут є вся необхідна для навчання техніка. Ми надаємо доступ до інтерактивних занять, які пов’язані з технологіями та онлайн-активностями, безпекою в інтернеті та медіаграмотністю», – зазначила Малмі Вірасірі.
			
			Відвідати центр можна з 9:00 до 17:00 за адресою Лінкольна, 23, там всіх відвідувачів зустрінуть фасилітатори та супервізор.
	
			На Львівщині це вже третій такий навчальний центр, який вдалось відкрити завдяки співпраці між Save the Children in Ukraine, Міністерство освіти і науки України та Львівська обласна державна адміністрація, у майбутньому кількість центрів збільшать вдвічі! Але вже зараз більш як пів тисячі дітей скористались можливостями, які вони надають.`
		},
		{
			title: 'Крути- одна з трагічних і водночас легендарних сторінок в історії українських визвольних змагань 1917-1921 років',
			date: '30 січня 2023 року',
			images: ['image/news/picture-12.jpg'],
			text: `Крути- одна з трагічних і водночас легендарних сторінок в історії українських визвольних змагань 1917-1921 років. Завдяки звитязі та сміливості українських воїнів більшовицький наступ на Київ було зупинено.
			Сьогодні, як і 105 років тому, ми маємо того ж ворога, а наші доблесні воїни мужньо боронять та відвойовують кожен сантиметр нашої землі.
			30.01.2023р. у ДПТНЗ ЛВПУКТБ відбувся виховний захід, приурочений пам'яті Героїв Крут. Учні групи 381 з класним керівником Бабаченко В.І. та майстром виробничого навчання Кандибою  С.Ю.  відтворили у пам'яті ці буремні  події.
			Ми незламні, ми непереможні, ми єдині...`
		},
		{
			title: '22 січня Україна відзначає День Соборності',
			date: '22 січня 2023 року',
			images: ['image/news/picture-11.jpg'],
			text: `22 січня Україна відзначає День Соборності. Це свято було започатковане на честь Акта Злуки УНР і ЗУНР, проголошеного на Софіївському майдані 22 січня 1919р.
			Нині ми бачимо, як події сторічної давнини повторюються і набувають нових сенсів. Ми засвоїли уроки історії і розуміємо, що в єдності наша сила, а згуртованість - запорука ПЕРЕМОГИ.
			23.01.2023р. у ДПТНЗ ЛВПУКТБ було проведено виховні години, приурочені Дню Соборності України.`
		},
		{
			title: 'Злагоджена робота закладів освіти усіх рівнів',
			date: '02 лютого 2023 року',
			images: ['image/news/picture-10.jpg'],
			text: `Злагоджена робота закладів освіти усіх рівнів - запорука успіху! 02 лютого 2023 року у ДПТНЗ "Львівське вище професійне училище комп'ютерних технологій та будівництва" відбулася надзвичайно тепла зустріч з освітянами СЗШ № 54 їм. Квітки Цісик,СЗШ N 78 м.Львова щодо організації уроків трудового навчання на базі училища. Такий комплексний підхід до освіти допоможе учням загальноосвітніх шкіл заздалегідь  обрати омріяну професію. Результатом зустрічі є підписання Меморандуму про співпрацю між закладами освіти.`
		},
		{
			title: 'Пробні кваліфікаційні роботи на присвоєння другого розряду',
			date: '02 лютого 2023 року',
			images: ['image/news/picture-9.jpg'],
			text:  `02 лютого 2023 року в ДПТНЗ "Львівське вище професійне училище комп'ютерних технологій та будівництва" відбулися пробні кваліфікаційні роботи на присвоєння 2-го кваліфікаційного розряду з професії "Електрогазозварник" у гр. №385 (майстер в/н Новосядлий М.Г.) та гр. №386 (майстер в/н Бажило Р.В.). Здобувачі освіти продемонстрували хороші практичні навички та вміння з технологічного процесу зварювання відповідно до загальнопрофесійних вимог.`
		},
		{
			title: 'Чи знаєте ви, що 48% українців мають низький рівень цифрових навичок?',
			date: '28 листопада 2022 року',
			images: ['image/news/picture-8.jpg'],
			text:  `🧑‍🎓Чи знаєте ви, що 48% українців мають низький рівень цифрових навичок? 
			Учні ДПТНЗ ЛВПУКТБ долучаються до масштабної інформаційної кампанії Тиждень цифрової освіти, щоб це змінити! 
			Протягом 08.12 -09.12  група 102 вимірювала свої  цифрові скіли завдяки тесту Цифрограм, переглянули освітній серіал "Штучний інтелект" й отримали електронний сертифікат
			Разом з платформа Дія.Цифрова освіта, зробити це було так само просто, як з’їсти тістечко 🍰😉
			🤩 Увірвемося у 2023-й діджитально!
			`
		},
		{
			title: 'Сьогодні ми всі - ВОЛОНТЕРИ!',
			date: '27 листопада 2022 року',
			images: ['image/news/picture-7.jpg'],
			text:  `Україна - волонтерський хаб!
			Війна триває. Ми впевнено показуємо cвітові свою незламність. Нині, як ніколи, важливо триматися разом і боротися з агресором. 
			Війни перемагають не тільки зброєю, а й завдяки волонтерам.Дякую колективу ДПТНЗ ЛВПУКТБ! В єдності - наша сила!🇺🇦🙏🤝 Уклін і подяка ЗСУ!`
		},
		{
			title: 'Голодомор',
			date: '26 листопада 2022 року',
			images: ['image/news/picture-6.jpg'],
			text: `26 листопада 2022 року в Україні і  світі вшановують пам'ять жертв Голодомору, яка цьогоріч проходить в умовах війни під гаслом  
			,, Пам'ятаємо. Єднаємося. Переможемо" 
			Знову, як і 90 років назад, росія проти українців застосовує геноцидну практику. Вона прагне знищити Українську ідентичність і націю. Тому, всі ми, УКРАЇНЦІ, приєднуємось  до акції ,, Запали свічку" та схиляємо свої голови перед пам'яттю жертв Голодомору.:pray: 
			ПАМ'ЯТАЄМО про страшний злочин сталінського режиму, ЄДНАЄМОСЬ, бо це є запорукою нашої ПЕРЕМОГИ!`,
			link: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid02vLm2Ax3QQzUoYt4tZp16eFJiCvmJiYGD1H86ouDWeufqGSpoUYJauZvHET9AagQol%26id%3D100006400723701'
		},
		{
			title: 'Тиждень безпечного руху',
			date: '18 листопада 2022 року',
			images: ['image/news/picture-5.jpg'],
			text: `Тиждень безпечного руху – 2022! 18 листопада 2022 року у рамках проведення щорічного Тижня безпечного 
			руху в Україні у ДПТНЗ «ЛВПУКТБ» Кубай О.С., викладач спедпредметів професій автомобільного напряму, провів єдиний 
			національний урок «Безпечна дорога додому». Нині, в умовах повномасштабного вторгнення росії, увага до безпеки як своєї, 
			так і оточуючих має бути підвищеною, зокрема на дорозі. Одна з найпоширеніших причин ДТП – низька видимість пішоходів в темний час доби, 
			особливо в умовах відключення світла. Важливо, щоб  наші учні були максимально обізнані про світловідбивачі, які можуть врятувати їхнє життя. 
			Учні переглянули цікавий і пізнавальний відеоматеріал на тему: «Пильнуй життя, а не смартфон!» Творимо безпечну країну разом! 
			Усі заходи проводилися з дотриманням алгоритму дій під час «Повітряної тривоги».`
		},
		{	
			title: 'Патрульна поліція',
			date: '31 травня 2021 року',
			images: ['image/news/picture-2.png'],
			text: `31 травня 2021 року з метою попередження дорожньо-транспортних випадків за участі дітей у наш
				навчальний заклад завітали представники Управління патрульної поліції у Львівській області.
				Старші інспектори відділу зв'язків з громадськістю патрульної поліції - Олег...`
		},
		{
			title: 'Відбулись навчальні тренування з пожежної безпеки',
			date: '27 травня 2021 року',
			images: ['image/news/picture-3.png', 'image/news/picture-31.png', 'image/news/picture-32.png', 'image/news/picture-33.png', 'image/news/picture-34.png'],
			text: `Обізнаний - значить захищений!
				27 травня 2021 р. на базі ДПТНЗ “ЛВПУКТБ” спільно з Головним управлінням ДСНС України у Львівській
				області та департаментом
				 освіти і науки ЛОДА в особі Івана Олексійовича Пикуса, начальника відділу інновацій...`
		},
		{
			title: 'День відкритих дверей',
			date: '20 травня 2021 року',
			images: ['image/news/picture-4.png'],
			text: `У ДПТНЗ "ЛВПУКТБ" відбулося подвійне свято - День відкритих дверей та День вишиванки. Майбутні абітурієнти
				спробували замінити колесо в автомобілі, покласти та порізати плитку, працювати зварювальним апаратом,
				а також знайти неполадку в комп’ютері ...`
		},
		{
			title: 'Вітаємо з перемогою в конкурсі професійної майстерності!',
			date: '15 квітня 2021 року',
			images: ['image/news/picture-1.png'],
			text: `15 квітня 2021 року Богдан Принада, учень групи №361, став переможцем другого етапу Всеукраїнського
				конкурсу професійної майстерності серед здобувачів професійної (професійно-технічного) освіти Львівської 
				області з професії «Штукатур», а підготувала його майстер...`
		}, 
	]
];

export const ARTICLES_PROPS = [
	{
		seeMore: 'Дізнатись більше',
		moreNews: 'Більше новин'
	}
];

export const NAV_LIST = [
	['події', 'оголошення']
];