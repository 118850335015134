import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const PedagogicalCouncil = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Педагогічна рада">
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1EiY9CwF9rTDEQbsRahL12_VM3lPxwury/view" target="_blanck">						
					Положення про педагогічну раду
					</a>
				</h4>	
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1GBxm3QouKUT66kJNCuMFyvRefNv_M8rq/view?usp=sharing" target="_blanck">						
					2024_Наказ про створення педагогічної ради
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1KPsE4FKiRCW7dC4533-5tTcmcezwxYlr/view?usp=sharing" target="_blanck">						
					План роботи педагогічної ради на 2024_2025
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1cT03ClXCwRPOUcksm4dgdhJLlb2Eb_2n/view?usp=sharing" target="_blanck">						
					План роботи педагогічної ради ДПТНЗ "ЛВПУКТБ" на 2023-2024
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1NSyQkym__gEAoAdqh2gL5tkoetf94mhr/view?usp=sharing" target="_blanck">						
					2023_Наказ про створення педагогічної ради
					</a>
				</h4>
			
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default PedagogicalCouncil;