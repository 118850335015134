import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const SocialTeacher = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="Педагог соціальний">
			<br />
				<h4>Друк Христина Вікторівна</h4>
				<br />
				<h4>+38 (067) 342 20 42</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1zDMtjkgI0t6o7v3Qd976X_ZKF3fQZ9wE/view?usp=drive_link" target="_blanck">
                        План роботи соціального педагога на 2024-2025 н.р.
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1hwOemi2JjINIxqZVvt-SRBVKeOQE0yMO/view?usp=sharing" target="_blanck">
                        Стратегія розвитку закладу освіти
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/share/p/JfCMZckdBSpeQxCT/?mibextid=WC7FNe" target="_blanck">
					Проведення циклу заходів по протидії тероризму
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/share/p/1Q9sKW6d3E8NKxwr/?mibextid=WC7FNe" target="_blanck">
					Благочинна ініціатива для дітей-сиріт ЛВПУ КТБ
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/share/p/XKuYVuQQN8EXWTEa/?mibextid=WC7FNe" target="_blanck">
					Великодні подарунки для дітей з числа незахищених категорій і дітей ВПО, які навчаються у ЛВПУ КТБ
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/story.php?story_fbid=3419876268235697&id=100006400723701&mibextid=WC7FNe&rdid=BOozk8qgsF3a7z73" target="_blanck">
					Тренінг від міжнародної гуманітарної організації "Save the Children"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.instagram.com/p/C40UARKICjX/?igsh=aHdiajhmZXMyZm95&img_index=1" target="_blanck">
					Зустріч-бесіда на тему "Зупинимо булінг"
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13hACZ2LyVLyTlPqPHVlQqTluhvIbrNZg/view?usp=drive_link" target="_blanck">
					Година спілкування на тему: "Емоції під контролем: три способи подолати тривогу" 
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://www.facebook.com/share/p/187BePXTdu/?mibextid=WC7FNe" target="_blanck">
					Тренінг "Як знайти баланс між внутрішнім "Я", родиною, спільнотою, або власні ресурси"  
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default SocialTeacher;