import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Attestation = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="атестація">
			<br/>
<h4>
<a href="https://drive.google.com/file/d/1OVlA6CSORYhTUevJ5Ig9oQnpEiUC5rc0/view?usp=drive_link" target="_blanck">2024-2025 н.р. Наказ на створення астестаційної комісії</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1L4CCyZZTbwdodSTVsO633B_lGFRGlsb8/view" target="_blanck">Графік атестації педагогічних працівників у 2025 році</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1MMYpcFhmjuHHcK6rRNHmtF6mz9PAlLrJ/view?usp=drive_link" target="_blanck">Заходи з проведення атестації у 2024-2025 році.</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1Q0-3AuQN9SP5qCrS0MitOn5lcvPyqDrt/view?usp=drive_link" target="_blanck">Чергова атестація 2025. Списки педагогічних працівників</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1I6j6xkiHI-wa_WIxJm784bDYqj2o66xh/view?usp=drive_link" target="_blanck">2025_Атестація керівника на відповідність займаній посаді</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1v_GVWz6pcCC90vgcnXGECEaI7l10CT1d/view?usp=drive_link" target="_blanck">Наказ на створення астестаційної комісії 2023_2024</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1yHTGVixvi37QZkQAPBQ3_dkiaWeHPrEn/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">Наказ про результати атестації 2024</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1pLSd21ch3sJRz-d11_7FhU0nD7HdyYuG/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">Графік проведення атестації 2024</a>
</h4>
<br/>
<h4>
<a href="https://mon.gov.ua/ua/npa/pro-zatverdzhennya-polozhennya-pro-atestaciyu-pedagogichnih-pracivnikiv" target="_blanck">Положення про атестацію педагогічних працівників (Нова редакція)</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1ffJ5CHa_9dz8JJG2-ZAreJgHgVxRrlCC/edit?usp=drive_link" target="_blanck">Чергова атестація_Інформація про педагогічних працівників 2024</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1SHPHIXoo8o1XihxGThoSP20h-KXzOK-s/edit?usp=drive_link" target="_blanck">Позачергова атестація_Інформація про педагогічних працівників 2024</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1xy_Y--PoJYJc4qIlNbmtq9QEUv3hpzcz/view?usp=drive_link" target="_blanck">Атестація педагогічних працівників по-новому. Інформаційний порадник</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1jpzqgpmasU8B0IjeJ47zunyImV1yVwb1/view" target="_blanck">Положення про атестацію педагогічних працівників ДПТНЗ "ЛВПУКТБ"</a>
</h4>
<br/>
<h4>
<a href="https://register.nqa.gov.ua/uploads/0/346-430_majster_virobnicogo_navcanna.pdf" target="_blanck">Професійний стандарт майстра в/н</a>
</h4>
<br/>
<h4>
<a href="https://mon.gov.ua/static-objects/mon/sites/1/rizne/2020/12/Standart_Praktychnyy_psykholoh.pdf" target="_blanck">Професійний стандарт практичного психолога закладу освіти</a>
</h4>
<h4>
<a href="https://ips.ligazakon.net/document/ME200569" target="_blanck">Професійний стандарт методиста закладу ЗП(ПТ)О</a>
</h4>


			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Attestation;