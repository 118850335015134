import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const EducationWork = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="виховна робота" target="_blanck">
				<br/>
			<h4><a href='https://docs.google.com/document/d/1jCJwGaXH9-9pvLEKgzplworn3TPl6pTk/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true'>Положення про виховну роботу</a></h4>
			<br/>

			<h4><a href='https://docs.google.com/document/d/1VvZsH1Xfy8zWmJrtSGjZ-W11i3fv3dMA/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true'>План виховної роботи</a></h4>
			<br/>

				<h4><a href='https://youtu.be/h8EtM4ILLqs'>9 березня 2023 року виповнюється 209 років від дня народження Великого Кобзаря - Тараса Шевченка.
					У ДПТНЗ "ЛВПУКТБ" пройшов спільний флешмоб педагогічних працівників та здобувачів освіти, приурочений Шевченківським дням.</a></h4>
					<br/>

				<h4><a href='https://drive.google.com/file/d/1ABHbqjarNh2gQ-7SVm2GFj1OFfVTYYfC/view?usp=sharing'>ОРГАНІЗАЦІЯ ВИХОВНОЇ РОБОТИ У ДПТНЗ «ЛЬВІВСЬКЕ ВИЩЕ ПРОФЕСІЙНЕ УЧИЛИЩЕ КОМП’ЮТЕРНИХ ТЕХНОЛОГІЙ ТА БУДІВНИЦТВА»</a></h4>
				<br/>
			<h4><a href='https://drive.google.com/file/d/1vbWBgVey9AXw11pmnCeRKcZi-Xs67kgI/view?usp=sharing'>План виховної роботи ДПТНЗ "ЛВПУКТБ на 2023-2024 н.р.</a></h4>
			<br/>
			<h4><a href='https://drive.google.com/file/d/1vbWBgVey9AXw11pmnCeRKcZi-Xs67kgI/view?usp=sharing'>Звіт про виховну роботу ДПТНЗ "ЛВПУКТБ" за 2023-2024</a></h4>
				<br/>
			<h4><a href='https://drive.google.com/file/d/1VhYVow4ZJ3DDzFCSS6DN4A54_WHk732k/view?usp=drive_link'>План виховної роботи ДПТНЗ "ЛВПУКТБ" на 2024-2025</a></h4>
			
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default EducationWork;