import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const StudentGoverment = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="учнівське самоврядування">

			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1cR4MN-ZjldP6uzCPHsiSbpgMlsn1K_2E/view?usp=drive_link" target="_blanck">Положення про учнівську раду гуртожитку КЗ ЛОР "ЛВПУ КТБ"</a>
			</h4>
			<br/>
			<h4>
			<a href="https://drive.google.com/file/d/1YutDtjaIWJUnNBrywSmcgHoF0aBM6Ryt/view?usp=drive_link" target="_blanck">Положення про учнівське самоврядування КЗ ЛОР "ЛВПУ КТБ"</a>
			</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default StudentGoverment;